/**
 * Styles targeting the Carousel Feature
 *
 */

.view-carousel{

  .cycle-slideshow {

    .carousel-slide{
    
      .slide-image{

        .overlay-text {
          font-size: 55px;
          line-height: 1;
          font-family: $wcm-bold;
          color: $wcm-dark-orange;
          @include breakpoint($sm) {
            display: block;
          }
          @include breakpoint($md) {
            @include center();
          }
        }

      }//.slide-image

      .slide-text{
        background: $wcm-dark-gray;
        @include breakpoint($md) {
          opacity: .8;
        }

        > .container{
          position: relative;
          margin-right: auto;
          margin-left: auto;
          padding-left: 20px;
          padding-right: 20px;
        }

        h2{
          font-size: 18px;
          line-height: 24px;
          @include breakpoint($md) {
            width: 30%;
            padding: 20px 0 10px 10px;
          }
        }

        p{
          font-size: 13px;
          line-height: 18px;
          color: $wcm-white;
          @include breakpoint($sm) {
            font-size: 14px;
            line-height: 20px;
          }
          @include breakpoint($md) {
            width: 70%;
            padding-left: 40px;
          }
        }

      }//.slide-text

    }//.carousel-slide

  }//.cycle-slideshow

  .thumbnail-controller{
    padding: 6px 0 0;
    background: transparent;

    @include breakpoint($sm) {
      @include clearfix();
    }

    .pager {
      margin-top: 0;
      font-weight: 600;
      color: $wcm-dark-orange;
    }

    #thumbnail-pager{
      a {
        border: none;

        > img {
          border: 3px solid $wcm-border-gray;
          opacity: .5;
        }
      }

      a > img:hover,
      .cycle-pager-active > img {
        border: 3px solid $wcm-bright-orange;
        opacity: 1;
      }

    }//#thumbnail-pager

  }//.thumbnail-controller

  .mobile-pager{
    @include clearfix();
    margin-bottom: 20px;

    p#slide-counter {
      height: auto;
      padding: 10px 0;
      background: $wcm-dark-gray;
    }

    a {
      float: left;
      width: 35%;
      font-family: $font-family-sans-serif;
      margin: 10px 0 0;
      @include text-hide();
      &:before {
        font-size: 30px;
        color: $wcm-dark-orange;
        @include fontello();
        text-decoration: none;
      }
    }
    
    .previous:before {
      content: '\e810';
    }

    .next:before {
      content: '\e802';
    }

  }//.mobile-pager

}//.view-carousel
