/**
 * Styles targeting navigational elements
 *
 */

/* =Main Navigation
----------------------------------------------------------*/

nav.main-nav {
    background: none;
  }
  