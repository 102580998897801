/**
 * Styles targeting the Main Header
 *
 */

/* =Skip Link
----------------------------------------------------------*/

#skip-link a {
  padding: 6px;
  position: absolute;
  top: -40px;
  left: 0px;
  color: white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  background: $wcm-dark-orange;
  transition: top 1s ease-out;
  z-index: 100;

  &:focus {
    position: absolute;
    left: 0px;
    top: 70px;
    outline-color: $wcm-bright-orange;
    transition: top .1s ease-in;
  }
}

/* =Header Container
----------------------------------------------------------*/

.brand__nav>.wrap {
  background: $wcm-dark-orange;
  height: 70px;
  @include clearfix();
}

/* =Menu
----------------------------------------------------------*/

.main-menu-toggle {
  width: 60px;
  height: 50px;
  float: left;
  cursor: pointer;
  color: $wcm-white;
  font-size: 12px;
  text-align: center;
  margin: 10px 0;
  padding: 0 50px 0 20px;
  border-right: 1px solid $wcm-bright-orange;

  @include breakpoint($md) {
    height: 70px;
    margin: 0;
    padding: 8px 50px 8px 20px;
    transition: all 0.25s 0s;
  }

  &:before {
    content: '\e808';
    font-size: 34px;
    @include fontello();
  }

  &:hover,
  &:focus {
    @include breakpoint($md) {
      background: $wcm-red;
    }
  }
}

.main-menu-toggle.active {
  background: $wcm-black;
  height: 70px;
  margin: 0;
  padding: 8px 50px 8px 20px;
  border-right: 1px solid #000;

  &:before {
    content: '\e809';
  }

  &:focus {
    background: $wcm-red;
  }
}

/* =White WCM Logo
----------------------------------------------------------*/

.wcm-sm-white {
  width: 160px;
  float: left;
  margin: 22px 0 0 10px;

  @include breakpoint((min: 340px)) {
    width: 180px;
  }

  @include breakpoint((min: 370px)) {
    width: 210px;
  }

  @include breakpoint($sm) {
    width: 260px;
  }

  @include breakpoint($md) {
    margin-right: 20px;
  }
}

.brand__nav-site-logo .wcm-sm-white {
  @include breakpoint($md) {
    display: none;
  }
}

/* =Branding / Logo Lockup
----------------------------------------------------------*/

.brand {
  padding: 70px 0 0;

  img {
    height: 84px;
  }
}

.pinned-header-fixed .brand {
  padding: 0;
}

.brand__nav {
  @include breakpoint($xs) {
    margin: 0 0 5px;
  }
}

.brand__lockup {
  padding: 20px 0;
}

.brand__lockup a {
  border: none;
}

.wcm-site-title {
  display: block;
  font-size: 36px;
  line-height: 1.2;
  color: $wcm-red;
  text-align: center;
  padding: 5px 0;

  @include breakpoint($sm) {
    font-size: 54px;
  }

  &:hover {
    color: $wcm-bright-orange;
  }
}

.wcm-site-title--lockup {
  @include breakpoint($md) {
    display: none;
  }
}

.wcm-site-logo {
  display: none;

  @include breakpoint($md) {
    display: inline-block;
  }
}

/* =WCM Global Links
----------------------------------------------------------*/

.wcm-global-links {
  float: left;
  margin: 0;
  padding: 0;
  height: 70px;
  display: none;

  @include breakpoint($md) {
    display: flex;
  }

  li {
    list-style-type: none;
    padding: 10px 0;

    &:hover {
      @include breakpoint($md) {
        background: $wcm-red;
      }
    }

    a {
      color: $wcm-white;
      display: block;
      border-right: 1px solid $wcm-bright-orange;
      padding: 12px 20px;

      &:hover {
        color: $wcm-white;
        text-decoration: none;
      }

      &:focus {
        @include breakpoint($md) {
          color: $wcm-white;
          background: $wcm-red;
        }
      }
    }
  }

  li.active {
    background: $wcm-white;

    &:hover {
      background: $wcm-red;

      a {
        color: $wcm-white
      }
    }

    a {
      color: $wcm-dark-orange;
      border: none;

      &:hover {
        color: $wcm-white;
      }
    }
  }

  li.first {
    a {
      border-left: 1px solid $wcm-bright-orange;
    }
  }

  li.first.active {
    border: none;
  }

  li.last {
    a {
      border: none;
    }
  }
}

li.wcm-global-links__wcm.first a {
  border-left: none;
}

/* =Branding
----------------------------------------------------------*/

.brand__logo {
  float: none;
}

.brand__logo--mobile {
  width: 90%;
  margin: 30px auto 10px;
  padding: 0 0 20px;
  border-bottom: 5px solid #cf4520;
  text-align: center;

  @include breakpoint($sm) {
    display: none;
  }
}

.brand__logo--mobile span {
  display: block;
  height: 24px;
  background: url(../images/wcm_logo.svg) no-repeat center center;
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;

  @include breakpoint($sm) {
    display: none;
  }
}

/* =Secondary Header Actions
----------------------------------------------------------*/

.wcm-global-links--secondary-actions {
  float: right;
}

/* =Search Button
----------------------------------------------------------*/

.search-trigger {
  float: left;
}

.search-trigger .search-trigger-button {
  display: block;
  width: 70px;
  height: 50px;
  cursor: pointer;
  padding: 8px 15px;
  margin: 10px 0;
  transition: all 0.25s 0s;
  border-left: 1px solid $wcm-bright-orange;

  @include breakpoint($md) {
    height: 70px;
    margin: 0;
    padding: 18px 15px;
    border-left: none;
  }
}

.search-trigger .cta-icon-search {
  width: 24px;
  height: 24px;
  fill: $wcm-white;
  color: $wcm-white;
  position: relative;
  top: 5px;
  left: 8px;
}

.search-trigger .search-trigger-button--close {
  display: none;
}

/*   H O V E R   */

.search-trigger .search-trigger-button:hover,
.search-trigger:focus {
  @include breakpoint($md) {
    background-color: $wcm-red;
  }
}

/*   O P E N   */

.search-trigger.open .search-trigger-button--find {
  display: none;
}

.search-trigger.open .search-trigger-button--close {
  display: block;
}

.search-trigger.open .search-trigger-button {
  height: 50px;
  background: none;

  @include breakpoint($md) {
    height: 70px;
    margin: 0;
  }

  &:hover {
    @include breakpoint($md) {
      background: $wcm-red;
    }
  }
}

.search-trigger.open .cta-icon-close {
  position: relative;
  width: 30px;
  height: 30px;
  top: 0;
  left: 5px;
  fill: $wcm-white;
}

.search-trigger.open .search-trigger-button:hover .cta-icon-close {
  fill: $wcm-white;
}

/* =Search Form
----------------------------------------------------------*/

.global-search {
  .global-search-input {
    outline: none;

    @include breakpoint($xs) {
      font-size: 24px;
    }

    &:focus {
      outline: 2px solid $wcm-dark-orange;
      outline-offset: -2px;
    }
  }

  .search-button-span {
    position: relative;
    float: right;
    width: 40px;
    height: 40px;
    background: $wcm-dark-orange;
    border: 2px solid $wcm-dark-orange;
    border-radius: 50%;

    .btn-primary:focus {
      outline: 5px solid $wcm-dark-orange;
      outline-offset: 0;
    }
  }

  .radio {
    @include breakpoint($xs) {
      float: none;
    }
  }
}

/* =Wayfinder
----------------------------------------------------------*/

.brand__nav .wayfinder {
  float: left;
  display: none;
  margin: 10px 15px;

  @include breakpoint($md) {
    display: block;
  }
}

.wayfinder {
  font-size: 12px;

  >h2.element-invisible {
    // to satisfy WAVE check
    background-color: #fff;
  }

  button {
    background: none;
    color: $wcm-white;
    border: 2px solid $wcm-white;
    border-radius: 10px;
    margin: 10px 0 0;
    padding: 4px 100px 4px 10px;

    &:focus {
      background: $wcm-white;
      color: $wcm-black;
      outline: 5px auto $wcm-dark-orange;
    }
  }

  button:focus .selection-arrow {
    &:after {
      color: $wcm-bright-orange;
    }
  }

  .selection-arrow {
    width: 45px;
    height: 20px;
    display: block;
    position: absolute;
    top: 16px;
    right: 1px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    border-left: 1px solid $wcm-border-gray;

    &:after {
      content: '\e80f';
      color: $wcm-white;
      @include fontello();
      display: block;
    }
  }

  .dropdown-menu li {
    padding: 2px 0;
    border-top: 1px solid $wcm-border-gray;

    &:first-child {
      border: none;
    }
  }

  .dropdown-menu>li>a:hover {
    color: $wcm-dark-orange;
  }

  .dropdown-menu>.active>a {
    background: transparent;
    color: #333;
  }
}

.wayfinder:hover,
.wayfinder.open {
  button {
    background: $wcm-white;
    color: $wcm-black;
  }

  .selection-arrow {
    border-left: 1px solid $wcm-bright-orange;

    &:after {
      color: $wcm-dark-orange;
    }
  }
}

/* =Title Bar
----------------------------------------------------------*/

.title-bar {
  display: none;
}

/* =Global Links
----------------------------------------------------------*/

.nav>.global-links__wcm {
  display: none;
}

/* =Pinned Header
 ----------------------------------------------------------*/

#pinned-header {
  position: relative;
  z-index: 989;

  @include breakpoint($md) {
    transition: box-shadow 0.3s ease-in-out;
  }
}

.navbar #pinned-header {
  top: 39px;
  position: fixed;
  right: 0;
  left: 0;
}

.navbar.navbar-tray-open #pinned-header {
  top: 78px;
  position: fixed;
  right: 0;
  left: 0;
}

.no-navbar #pinned-header {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
}

.navbar.pinned-header-fixed #pinned-header,
.no-navbar.pinned-header-fixed #pinned-header {
  position: relative;
  top: -2px;
}

#pinned-header.pinned {
  @include breakpoint($md) {
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.3);
  }
}

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  will-change: transform, opacity;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.animated.slideDown {
  animation-name: slideDown;
}


@keyframes slideUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.animated.slideUp {
  animation-name: slideUp;
}

.wcm-search {
  background: $wcm-white;
}
