
h1{
	margin: 20px 0 25px;
}

/*h3.pane-title a{
	color: $wcm-red;
	&:after{
		content: "";
	    display: inline-block;
	    position: relative;
	    border-top: 5px solid transparent;
	    border-bottom: 5px solid transparent;
	    border-left: 5px solid #e87722;
	    margin-left: 5px;
	}
	&:hover{
		color: $wcm-bright-orange;
	}
}

h3 a{
	color: $wcm-red;
	&:after{
		content: "";
	    display: inline-block;
	    position: relative;
	    border-top: 5px solid transparent;
	    border-bottom: 5px solid transparent;
	    border-left: 5px solid #e87722;
	    margin-left: 5px;
	}
	&:hover{
		color: $wcm-bright-orange;
	}
}*/


.block-menu-block .content {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
    width: 82%;
}

#biblio-header, .biblio-category-section{
	max-width: 1180px;
	padding: 0 20px;
	margin: 0 auto;
	>div{
	
	}
}

.pane-node-updated{
	float: left;
	width: 100%;
	font-size: 20px;
	line-height: 1.1;
	margin: 20px 0;
	font-weight: bold;
	h3{
		float: left;
		margin: 0px 5px 0 0 ;

	}
}

.view-id-news.view-display-id-panel_pane_1{

	.view-teaser{margin-top:20px;}

	h3{
		margin-top: 3px;
	}

	.post-date{
		font-size: 13px;
		font-weight: bold;
		padding-bottom: 10px;
	}

	.teaser-image img{
		margin-right: 20px;
	}
}

#edit-field-news-category-value{
	width: 270px;
	max-width: none;
}
.pane-node-field-news-featured-image{
	margin-top: 20px;
}

//Biblio 

#biblio-header{
	display: block;
	height: 50px;
	
	ul{
		float: left;
		width: 100%;
		height: 20px;
		list-style: none;
		padding-left: 0px;
		li.active{
			a{color: $wcm-bright-orange;}
			
			div.asc{
				width: 20px;
				height: 20px;
				font-size: 13px;
			    line-height: inherit;
			    content: '\e80b';
			    text-decoration: none;
			    color: #cf4520;
			    font-family: "fontello";
			    font-style: normal;
			    font-weight: normal;
			    speak: none;
			    text-decoration: inherit;
			    text-align: center;
			    font-variant: normal;
			    text-transform: none;
			    line-height: 1em;
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;
			    -webkit-transition: all 0.25s;
			    -o-transition: all 0.25s;
			    transition: all 0.25s;
			    padding-left: 10px;
			}
			div.desc{
				width: 20px;
				height: 20px;
				font-size: 13px;
			    line-height: inherit;
			    content: '\e80b';
			    text-decoration: none;
			    color: #cf4520;
			    font-family: "fontello";
			    font-style: normal;
			    font-weight: normal;
			    speak: none;
			    display: inline-block;
			    text-decoration: inherit;
			    text-align: center;
			    font-variant: normal;
			    text-transform: none;
			    line-height: 1em;
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;
			    -webkit-transition: all 0.25s;
			    -o-transition: all 0.25s;
			    transition: all 0.25s;
			    padding-left: 10px;
			}
			.demo-icon{    
				font-family: "fontello";
			    font-style: normal;
			    font-weight: normal;
			    speak: none;
			    display: inline-block;
			    text-decoration: inherit;
			    width: 1em;
			    margin-right: .2em;
			    text-align: center;
			    font-variant: normal;
			    text-transform: none;
			    line-height: 1em;
			    margin-left: .2em;
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;
			  }
		}
		li{
			float: left;
			padding-right: 20px;
			a{
				color: #777;
			}
		}
	}
}

.member__bio a {
	font-size: 14px;
	background: url("../images/clinprof.svg") 0 0 no-repeat;
	background-size: 32px 32px;
	padding-left: 42px;
	height: 32px;
	line-height: 32px;
	display: inline-block;
	border-bottom: none;
	&:hover {
		text-decoration: underline;
	}
}

.protitle{
	font-weight: bold;
}

.member{
	.member__name{
		margin-top: 0px;
	}
	float: left;
	width: 100%;
	margin-top: 20px;
	img.headshot{
		float: left;
		margin-right: 20px;
	}
}
.member__title div.item-list ul li.first{
	padding: 0;
	margin: 0;
	list-style: none;
}

.member__education div.item-list ul li.first{
	padding: 0;
	margin: 0;
	list-style: none;
}
.member__education div.item-list ul li.last {
	padding: 0;
	margin: 0;
	list-style:none;
}

.pane-lab-members-panel-pane-2, .pane-lab-members-panel-pane-1{
	float: left;
	margin-bottom: 20px;
	width: 100%;
}

.home_cta_lab{
	h3{
		margin-bottom: 20px;
	}
}

.primquote p{
	color: #cf4520;
    margin-top: 8px;
    font-size: 14px;
    font-family: "1898Sans-Regular",sans-serif;
    padding-left: 22px;
    border-left: 5px solid #e87722;
    margin-top: 0px;
}

.primquote blockquote{
	margin-bottom: 0;
}

.hero-image-full-caption figcaption{
	padding-bottom: 20px;
	width: 100%;
}


.views-field-changed{
	padding-bottom: 10px;
	span{
		font-size: 13px;
		font-weight: bold;
	}
}

.view-lab-members{
	.member__name{
		font-weight: bold;
	}
	.member__title ul li{
		font-weight: normal;
		font-style: italic;
		list-style: none;
	}
}

.wcm-cta__tile:nth-child(1){
	border: none;
	display: block;
	float: none;
	margin: 0 auto;
}

div.field-label{
	float: left;
	font-weight: bold;
}

#views-exposed-form-lab-members-panel-pane-1{
	.select2{
		width: 250px;
	}
	.form-submit{
		margin-top: 2.2em;
	}
}

.pane-node-field-project-image{
	img{
		width: 100%;
	}
}

.views-label-field-job-start-date, .views-label-created{
	float: left;
	font-weight: bold;
	padding-right: 5px;
}

.information-sidebar {
	float: left;
}

/*#thumbnail-pager a>img {
	height: auto;
}*/

.pane-wcmc-hero img{
	width: 100%;
}