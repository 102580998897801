/**
 * Styles related to site Typography
 *
 * Note: These load after some general type styles are set in _variables.scss
 */


h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
  margin: 20px 0 0;
  a {
    border-bottom: none !important;
    color: $wcm-red;
    &:after {
      content: '\e80d';
      color: $wcm-bright-orange;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

h1.title {
  font-size: 40px;
  margin: 20px 0;
}

h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  line-height: 1.3;
}

h4, .h4,
h5, .h5,
h6, .h6 {
  color: $wcm-dark-gray;
}

a {
  border-bottom: 1px dotted;
  &:hover {
    color: $wcm-red;
    text-decoration: none;
    border-bottom: 1px solid;
  }
  &:focus {
    color: $wcm-red;
    text-decoration: none;
    border-bottom: 1px solid;
  }
}

header a,
nav a,
footer a {
  border: none;
  &:hover {
    text-decoration: underline;
    border: none;
  }
}

blockquote {
  color: $wcm-med-gray;
  font-size: 40px;
  font-family: $wcm-italic;
  line-height: 56px;
  border-left: 5px solid $wcm-bright-orange;
  @include breakpoint($sm) {
    font-size: 46px;
  }
}

blockquote > p {
  line-height: 56px;
}

#drawer-nav .external-link:after,
#active-second-level-nav .external-link:after,
#active-third-level-nav .external-link:after,
.site-main .external-link:after,
.footer-nav .external-link:after {
  content: '\e811';
  padding-left: 5px;
  @include fontello();
  text-decoration: none;
}
